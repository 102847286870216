import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('center-screen',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"text":"","color":"primary","tile":"","depressed":""},on:{"click":_vm.navigateToLogin}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","tile":"","depressed":""},on:{"click":_vm.tryResetPassword}},[_vm._v(" "+_vm._s(_vm.$t("general.reset"))+" ")])]},proxy:true}])},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"rules":_vm.rules.password,"label":_vm.$t('user.newPassword'),"name":"password","append-icon":"mdi-lock","type":"password"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"rules":_vm.rules.confirmPassword,"label":_vm.$t('user.confirmPassword'),"name":"confirm","append-icon":"mdi-lock-check","type":"password"},model:{value:(_vm.model.confirmPassword),callback:function ($$v) {_vm.$set(_vm.model, "confirmPassword", $$v)},expression:"model.confirmPassword"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }